.servicepage {
  background: rgb(19, 117, 0);
  background: linear-gradient(
    301deg,
    rgba(19, 117, 0, 1) 0%,
    rgba(3, 88, 0, 1) 100%
  );
}
.servicepage .container {
  height: auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
.servicepage-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.meat-img {
  width: 600px;
  height: auto;
  object-fit: cover;
}
.meat-img img {
  width: 100%;
  object-fit: cover;
}

.servicepage-header-text .title {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
  .meat-img {
    width: 550px;
  }
  .servicepage-header-text .title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .meat-img {
    width: 500px;
  }
  .servicepage-header-text .title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .meat-img {
    width: 350px;
  }
  .servicepage-header-text .title {
    font-size: 25px;
  }
  .servicepage-header-text .paragraph {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .servicepage {
    margin-bottom: 100px;
  }
  .servicepage-header-text .title {
    font-size: 35px;
  }
  .meat-img {
    width: 500px;
  }
}
@media only screen and (max-width: 600px) {
}
@media only screen and (max-width: 479px) {
  .meat-img {
    width: 400px;
  }
}
@media only screen and (max-width: 400px) {
  .meat-img {
    width: 350px;
  }
}
@media only screen and (max-width: 350px) {
  .meat-img {
    width: 300px;
  }
}
@media only screen and (max-width: 300px) {
  .meat-img {
    width: 260px;
  }
}
