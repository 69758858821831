.knowmeat{
    margin-bottom: 100px;
}

.knowmeat-content{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    height:auto;
}
.knowmeat-header h1{
    font-size: 40px;
    font-weight: 700;
    color:#fff;
}
.knowmeat-header p{
    font-size: 22px;
    font-weight:normal;
    color:#fff;
}
.knowmeat-search{
    display: flex;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
}
.knowmeat-search input{
    height:60px;
    border-radius:0px;
}
.knowmeat-search button{
    height:60px;
    font-size: 20px;
    font-weight: 600;
    border-radius:0px;
    border-style: none;
    background-color: #0AA650;
}
.knowmeat-search button:hover{   
    background-color: #00863c;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .knowmeat-header h1{
        font-size: 30px;
    }
    .knowmeat-header p{
        font-size: 20px;   
    }
    .knowmeat-search button{         
        font-size: 18px;      
    }
  
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .knowmeat-search button{ 
        width:200px;        
        font-size: 16px;      
    }
}

@media only screen and (max-width: 767px) {
    .knowmeat-header h1{
        font-size: 26px;
    }
    .knowmeat-header p{
        font-size: 18px;   
    }
    .knowmeat-search button{ 
        width:200px; 
        font-size: 16px;                  
    }
    .knowmeat-content{
       margin-bottom: 40px;
    }
}

@media only screen and (max-width: 479px) {
    .knowmeat-logo img{
        width:50px;
        height:50px;
    }
}
@media only screen and (max-width: 390px) {
    .knowmeat-search{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .knowmeat-search input{
        width:300px;
    }
    .knowmeat-search button{ 
        width:300px;                       
    }
}