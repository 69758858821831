.meatreport-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border:2px solid black;
    padding:20px 60px 20px 60px;
    /* width:1000px; */
    height:auto;
}
.meatreport-logo{
    width:300px;
    height:60px;
    
}
.meatreport-logo img{
    width:100%;
    height:auto;
    object-fit: cover;
}
.meatreport-title h1{
    font-size: 40px;
    font-weight: 700;
    color:#17AF4E;
}
.meatreport-title span{
    color:#0F5EA2;
}

.meatreport-subheader{
    display: flex;
    align-items: center;
    width:100%;
    background-color: #17AF4E;
    padding:5px 0px 5px 30px;  
}
.meatreport-subheader p{
    font-size: 30px;
    color:#fff;
    text-align: left;
    margin-bottom: 0;
}

.data-content{
    text-align: left;
    padding-left: 30px;
}
.data-content h2{
    font-size: 30px;
    font-weight: 600;
}
.meatreport-data{
    display: flex;
    flex-wrap: wrap;
    column-gap:auto;
    row-gap:30px;
    justify-content: space-between;
    
}
.meatreport-data p{
    font-size: 24px;
    color:#505050;
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;   
}
.meatreport-data span{
    font-size: 22px;
    color:#000;
    font-weight:600;
}

.data-content .manager{
    font-size: 30px;
    color:#505050;
    margin-bottom: 0;
}
.data-content .manager span{
    color:#000;
    font-weight:600;
}
.download-btn{
    display:flex;
    padding: 0;
    justify-content: flex-end;   
}

.download-btn button{
    background-color: #0F5EA2;
    color:#fff;
    border-radius: 0;
    border-style: none;
    padding:20px 50px;
}

.general-information,.processing-detail,.meat-detail {
    background-color:#f8f8f8 ;
    border:0.5px solid #787878;
}

/* Responsive */
@media only screen and (min-width: 1200px) {
    
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .meatreport-title h1{
        font-size: 30px;  
    }
    .meatreport-subheader p{
        font-size: 20px;
    }
    .data-content h2{
        font-size: 24px;
    }
    .meatreport-data p{
        font-size: 18px;   
    }
    .meatreport-data span{
        font-size: 20px;
    }
    .data-content .manager{
        font-size: 24px;    
    }
  
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
    .meatreport-content{      
        padding:20px;    
    }
    .meatreport-subheader{
        padding:5px 0px 5px 10px;  
    }
    .data-content{
       
        padding-left: 10px;
    }
    .meatreport-title h1{
        font-size: 30px;  
    }
    .meatreport-subheader p{
        font-size: 20px;
    }
    .data-content h2{
        font-size: 24px;
    }
    .meatreport-data p{
        font-size: 18px;   
    }
    .meatreport-data span{
        font-size: 20px;
    }
    .data-content .manager{
        font-size: 24px;    
    }
    .download-btn{
        margin-bottom: 150px;
    }
}

@media only screen and (max-width: 600px) {
    .download-btn{
        padding-right: 12px;          
    }
    .download-btn button{    
        padding:10px 30px;
    }
}

@media only screen and (max-width: 479px) {
    .download-btn{
        padding-right: 12px;          
    }
    .download-btn button{    
        padding:10px 30px;
    }
}
@media only screen and (max-width: 390px) {
    .data-content .manager{
        font-size: 22px;    
    }  
}